import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { MeliPortfolio, Layout } from '_templates'
import { getLastCharAndFirstPart } from '_utils/string'
import { rawToJSON } from '_utils/functions'

const MeliCaseStudy = ({ data }) => {
  const content = data.contentfulMeliCaseStudy
  const { proudCases } = content

  const heroImages = {
    phone: {
      image: content.heroImages[0].file.url,
      alt: content.heroImages[0].description,
    },
    cart: {
      image: content.heroImages[1].file.url,
      alt: content.heroImages[1].description,
    },
    man: {
      image: content.heroImages[2].fluid,
      alt: content.heroImages[2].description,
    },
    woman: {
      image: content.heroImages[3].fluid,
      alt: content.heroImages[3].description,
    },
    logo: {
      image: content.heroImages[4].fluid,
      alt: content.heroImages[4].description,
    },
  }

  const echoDotMobile = content.heroImages[6]

  const challengeImages = {
    backBallon: {
      image: content.challengeImages[1].file.url,
      alt: content.challengeImages[1].description,
    },
    frontBallon: {
      image: content.challengeImages[2].file.url,
      alt: content.challengeImages[2].description,
    },
  }

  const discoverImages = {
    dialog1: {
      image: content.discoverImages[0].file.url,
      alt: content.discoverImages[0].description,
    },
    dialog2: {
      image: content.discoverImages[1].file.url,
      alt: content.discoverImages[1].description,
    },
    dialog3: {
      image: content.discoverImages[2].file.url,
      alt: content.discoverImages[2].description,
    },
    dialog4: {
      image: content.discoverImages[3].file.url,
      alt: content.discoverImages[3].description,
    },
    dialog5: {
      image: content.discoverImages[4].file.url,
      alt: content.discoverImages[4].description,
    },
    dialog6: {
      image: content.discoverImages[5].file.url,
      alt: content.discoverImages[5].description,
    },
    dialog7: {
      image: content.discoverImages[6].file.url,
      alt: content.discoverImages[6].description,
    },
  }

  const callToActionImages = {
    echo: {
      image: content.callToActionImages[0].file.url,
      alt: content.callToActionImages[0].description,
    },
    controller: {
      image: content.callToActionImages[1].file.url,
      alt: content.callToActionImages[1].description,
    },
    phone: {
      image: content.callToActionImages[2].file.url,
      alt: content.callToActionImages[2].description,
    },
    headset: {
      image: content.callToActionImages[3].file.url,
      alt: content.callToActionImages[3].description,
    },
    monitor: {
      image: content.callToActionImages[4].file.url,
      alt: content.callToActionImages[4].description,
    },
  }

  const challengeTitleContent = getLastCharAndFirstPart(content.challengeTitle)
  const discoverTitleContent = getLastCharAndFirstPart(content.discoverTitle)
  const discoverTitle2Content = getLastCharAndFirstPart(content.discoverTitle2)
  const ctaTitleContent = getLastCharAndFirstPart(content.callToActionTitle)

  return (
    <Layout navbarColorOverride="transparent">
      <MeliPortfolio
        pageTitle={content.pageTitle}
        pageDescription={content.pageDescription}
        thumbnail={content.pageThumbnail?.file?.url}
        echoDotMobileImage={echoDotMobile.fluid}
        echoDesktopImage={callToActionImages.echo.image}
        echoDesktopImageAlt={callToActionImages.echo.alt}
        heroDescription={rawToJSON(content.heroDescription.raw)}
        heroImages={heroImages}
        challangeDescription={rawToJSON(content.challengeDescription.raw)}
        challangeTitleFirstPart={challengeTitleContent.firstPart}
        challangeTitleLastChar={challengeTitleContent.lastChar}
        challengeImages={challengeImages}
        discoverTitleFirstPart={discoverTitleContent.firstPart}
        discoverTitleLastChar={discoverTitleContent.lastChar}
        discoverTitle2FirstPart={discoverTitle2Content.firstPart}
        discoverTitle2LastChar={discoverTitle2Content.lastChar}
        discoverDescription={rawToJSON(content.discoverDescription.raw)}
        discoverDescription2={rawToJSON(content.discoverDescription2.raw)}
        discoverImages={discoverImages}
        callToActionTitleFirstPart={ctaTitleContent.firstPart}
        callToActionTitleLastChar={ctaTitleContent.lastChar}
        callToActionDescription={rawToJSON(content.callToActionDescription.raw)}
        callToActionImages={callToActionImages}
        proudCases={proudCases}
      />
    </Layout>
  )
}
export const query = graphql`
  query MeliCaseStudyContent($locale: String) {
    contentfulMeliCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      heroDescription {
        raw
      }
      heroImages {
        description
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        file {
          url
        }
      }
      challengeTitle
      challengeDescription {
        raw
      }
      challengeImages {
        description
        title
        file {
          url
        }
      }

      discoverTitle2
      discoverDescription2 {
        raw
      }

      discoverTitle
      discoverDescription {
        raw
      }
      discoverImages {
        description
        title
        file {
          url
        }
      }
      callToActionTitle
      callToActionDescription {
        raw
      }
      callToActionImages {
        description
        title
        file {
          url
        }
      }
      proudCases {
        ... on ContentfulCaseStudy {
          id
          title
          main
          platforms {
            internal {
              content
            }
          }
          projectColor
          projectName
          thumbnail {
            description
            fixed {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
            file {
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`

MeliCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default MeliCaseStudy
